* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: Arial, sans-serif;
}

:root {
  color-scheme: dark;
  --item-size: 100px;
  --subclass-size: 180px;
  --subclass-size-small: 100px;
  --item-border-width: 2px;
  --item-grid-gap: 7px;
  --item-hover-transition-time: 0.2s;
  --main-content-max-width: 1600px;
  --gridpager-offset: 15px;
  --pager-offset: 300px;
  --blur-px: 3px;
  --button-background-color: rgba(60, 60, 60, 0.4);
  --weapon-type-icon-height: 70px;
  --weapon-type-icon-width: 100px;
  --nav-bar-height: 100px;
  --full-page-height: calc(100vh - var(--nav-bar-height));
  --legacy-color: #ad7f00;
}

body {
  /* overflow: hidden; */
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  /* border: 1px solid red; */
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  color: white;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.light-theme {
  background: linear-gradient(to bottom, rgb(121 121 119) 0, rgb(43 45 52) 50%);
}

.dark-theme {
  background: radial-gradient(circle, #6a8bac 0, rgb(43 45 52) 70%);
}

.main-content-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 1;
  /* border: 5px solid magenta; */
}

.main-content {
  position: relative;
}

.main-content-grid {
  position: relative;
}

@keyframes main-detail-anim {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.main-detail-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.main-detail {
  position: fixed;

  animation: main-detail-anim 360s linear infinite;
  /* border: 2px solid magenta; */
  opacity: 0.2;
  /* border-radius: 50%; */
  width: min(95vw, 95vh);
  height: min(95vw, 95vh);
  /* transform: translate(-50%, -50%); */
  top: 50%;
  left: 50%;
  /* background-image: url("./icons/masterwork_overlay.png"); */
}

.nav-bar {
  /* border-bottom: 1px solid magenta; */
  position: sticky;
  top: 0px;
  z-index: 100;
  height: var(--nav-bar-height);
  /* margin-bottom: 30px; */
  /* border: 1px solid magenta; */
  display: grid;
  grid-template-columns: 110px 1fr auto;
  grid-template-areas: "icon char tabs";
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 60px;
  gap: 0 20px;
  background-color: rgb(39, 44, 46);
}

.static-controls-container {

  /* height: 100px; */
  border: 2px solid red;
  /* padding: 0 calc(min(70px, 5vw)); */
}

.controls-content-container {
  backdrop-filter: blur(var(--blur-px));
  background-color: rgba(0, 0, 0, 0.6);
  /* max-width: var(--main-content-max-width); */
  padding: 10px;
  margin: 20px 20px 0 auto;
  width: fit-content;
  height: 100%;
  /* border: 2px solid lightgrey; */
}


.refresh-button {
  height: 50px;
  margin: auto 0;
}

.character-container.selected {
  border-color: white;
  outline: 2px solid lightgrey;
  outline-offset: 2px;
  filter: none;
}

/* .character-container.selected img {

} */

.character-info-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 1px solid magenta; */
  padding: 2px 2px 2px 60px;
  /* display: flex; */
}

.character-class-icon {
  /* border: 1px solid magenta; */
  height: 100%;
  display: inline-block;
}

.character-class-title {
  /* border: 1px solid yellow; */
  /* height: 100%; */
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: auto;
}



.char-img {
  display: block;
  /* width: 100%; */
  height: 100%;
}

.main-content-container {}

.inventory-container {
  /* flex: 1; */
  /* border: 1px solid magenta; */
  width: 100%;
  max-width: var(--main-content-max-width);
  padding: 30px 10px 500px 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3em;
  /* overflow: scroll; */
  /* overflow-x: hidden; */
}

.inventory {
  grid-template-columns: repeat(auto-fill, var(--item-size));
  display: grid;
  gap: var(--item-grid-gap);
  /* border: 1px solid magenta; */
  flex: 1;
}

.inventory-section-container {
  /* border: 1px solid red; */
}

.title-1 {
  /* font-size: 3em; */
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  /* letter-spacing: 0.2em; */
  font-weight: bold;
}

.title-2 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
}

.subtitle {
  padding: 0;
  margin: 0;
  color: lightgrey;
  font-weight: normal;
  font-size: 1.5em;
}

.hint {
  padding: 0;
  margin: 0;
  color: lightgrey;
  font-weight: normal;
}


.inventory-section-label {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
}

.inventory-section-bucket-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.bucket-label {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
}

.bucket-container {
  display: flex;
  width: 100%;
  /* border: 1px solid red; */
  gap: 30px;
  /* margin-bottom: 30px; */
}

@keyframes spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}

.loading-display-container {
  z-index: 500;
  position: fixed;
  top: 0;
  right: 20px;
  /* right: 0; */
  display: flex;
  padding-left: 2em;
  text-shadow: 1px 1px black;
  /* background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 80%, transparent); */
}

.loading-progress-label {
  font-weight: bold;
  margin: auto 0;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.loading-icon-container {
  padding: 10px;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.loading-icon {
  animation: spin linear 2s infinite;
  animation-play-state: repeat;
  width: 100%;
  height: 100%;
  /* filter: drop-shadow(1px 1px 0px black); */
}

button, .button {
  text-align: center;
  font-weight: normal;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background-color: var(--button-background-color);
  border: 1px solid lightgray;
  color: white;
  padding: 5px;
  font-size: 1em;
  text-transform: uppercase;
  transition: background-color 0.1s linear;
}

button:disabled, .button:disabled {
  color: rgb(119, 119, 119);
}

button:active, .button:active {
  background-color: rgb(104, 104, 104);
}

input[type=text], textinput {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background-color: rgb(71, 71, 71);
  border: 1px solid lightgray;
  color: white;
  padding: 5px;
  font-size: 1em;
}

.outline-mask {
  mask-border: 10px;
}



.item {
  z-index: 0;
  user-select: none;
  flex: none;
  position: relative;
  cursor: pointer;
  width: var(--item-size);
  height: var(--item-size);

  border: var(--item-border-width) solid lightgray;
  background-color: rgb(39, 39, 39);
  outline-style: solid;
  outline-width: var(--item-border-width);
  outline-offset: 5px;
  outline-color: rgba(255, 255, 255, 0);

  transition-property: outline-color, outline-offset, border-image, border-color;
  transition-timing-function: linear;
  transition-duration: var(--item-hover-transition-time);

  overflow: hidden;

  /* background-image: linear-gradient(to top, red, transparent); */
}

.item>* {
  pointer-events: none;
}

.item:hover {
  border-color: white;
  outline-color: lightgray;
  outline-offset: 2px;
}

.item-icon {
  display: block;
  z-index: -2;
  top: -1px;
  left: -1px;
  position: absolute;
  /* oversize makes sure the icon fills the box at all scales */
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.item-masterwork-overlay {
  display: block;
  z-index: 0;
  top: 0;
  left: 0;
  position: absolute;
  /* oversize makes sure the icon fills the box at all scales */
  width: calc(100%);
  height: calc(100%);

  border: 2px solid orange;
  opacity: 0.7;
}

.item.masterwork::after {
  content: "";
  position: absolute;
  top: 140%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(circle, rgba(255, 208, 79, 0.6), transparent 60%);
  /* background-image: url("./icons/masterwork_overlay.png"); */
  /* top: 0;
  left: 0;
  width: 100px;
  height: 100px; */
  /* border: 1px solid magenta; */
}

.item-watermark {
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.item-power-display {
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.6); */
  padding: 2px;
  padding-left: 15px;
  font-size: 1.3em;
  line-height: 1em;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 60%, transparent);
}

.item-power-energy-icon {
  height: 0.8em;
  padding: 1px;
  /* border: 1px solid red; */
}

.item-perk-preview-container {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.6); */
  padding: 4px;
  gap: 3px;
  padding-left: 15px;
  /* border: 1px solid magenta; */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 60%, transparent);
}

.item-perk-preview-container.legacy {
  background-image: linear-gradient(to left, rgba(202, 108, 0, 0.75) 60%, transparent);
}

.item-perk-preview-icon {
  position: relative;
  display: block;
  height: 1.75em;
  width: 1.75em;
  /* border: 1px solid magenta; */
}

.item-perk-preview-icon > img{
  width: 100%;
  height: 100%;
}

.item-perk-preview-icon.multi::after {
  color: lightgreen;
  font-size: 1.4em;
  content: "+";
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1em;
  /* border: 1px solid magenta; */
  transform: translate(0.15em, -0.3em);
  /* text-shadow: 0 0.05em 0.1em rgba(0, 0, 0, 0.75); */
  font-weight: bold;
}

.item-details-container {
  z-index: 10;
  width: 100%;
  height: 100%;
}

/* .item-details-container > :first-child{
  margin-top: auto;
  border: 1px solid red;
} */

.item-stat-preview-container {
  /* border: 1px solid magenta; */
  position: absolute;
  bottom: 0;
  right: 0;
  width: fit-content;
  padding: 2px;
  padding-left: 15px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6) 60%, transparent);
  font-size: 1em;
}

.item-stat-preview {
  text-align: right;
}

.item-crafted-background {
  /* border: 1px solid magenta; */
  background-image: linear-gradient(45deg, rgba(255, 86, 86, 0.7) 0%, transparent 40%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.item-crafted-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.25em;
  width: 1.9em;
  color: #f19b83;
  text-shadow: 1px 1px black;
  font-weight: bold;
}

.stack-size-display {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: lightgray;
  padding: 2px 0 0 3px;
  color: black;
  min-width: 30px;
  text-align: center;
  font-size: 1.4em;
  margin: 0 1px 1px 0;
}

.stack-size-display.max {
  color: rgb(255, 233, 194);
  background-color: rgb(131, 100, 59);
}

/* required!! */
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.deepsight {
  border-color: rgb(194, 0, 0);
}

.masterwork {
  --angle: 30deg;
  border: 3px solid;
  border-image: linear-gradient(var(--angle), rgb(224, 193, 16), white, rgb(224, 193, 16)) 1;
  /* filter: blur(var(--blur-px)); */
  /* WARNING: the masterwork rotation animation puts a large load on the CPU */
  /* animation: 10s rotate linear infinite; */
}

.masterwork-border {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masterwork:hover {
  /* border-color: white; */
  border-image: linear-gradient(0deg, white, white) 1;
}

.masterwork-inner {
  --angle: 30deg;
  border: var(--item-border-width) solid;
  border-image: linear-gradient(var(--angle), rgb(224, 193, 16), white, rgb(224, 193, 16)) 1;
  opacity: 0.5;
  /* WARNING: the masterwork rotation animation puts a large load on the CPU */
  /* animation: 10s rotate linear infinite; */
}

@keyframes rotate {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 360deg;
  }
}

@keyframes fadeIn {
  from {
   opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.inspect-card {
  z-index: 50;
  width: 450px;
  /* border: 1px solid red; */
  /* background-color: rgba(0, 0, 0, 0.75); */
  backdrop-filter: blur(var(--blur-px));
  position: absolute;
  /* padding: 10px; */
  margin: 0 5px;
  /* border: 2px solid darkgray; */
}

.inspect-card>* {
  user-select: none;
}

.inspect-card-header-container {
  padding: 10px;
  position: relative;
}

.inspect-card-header-backdrop {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 58, 58);
  opacity: 0.9;
}

.inspect-weapon-mod-container {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  /* border-bottom: 2px solid darkgray; */
}

/* .inspect-weapon-mod:first-child {
  margin-left: auto;
} */

.inspect-weapon-mod {
  width: 50px;
}

.inspect-title {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.inspect-card-roll-controls-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  /* border-bottom: 2px solid darkgray; */
}

.inspect-card-controls-container {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.inspect-card-controls-container> :first-child {
  margin-left: auto;
}

.inspect-card-content {
  padding: 0px;
  /* gap: 10px; */
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.75);
}

.inspect-damage-type-icon {
  display: block;
  height: 50px;
  width: 50px;
  /* margin: auto 5px auto 5px; */
  padding: 5px;
  /* border: 1px solid magenta; */
}

.inspect-ammo-type-icon {
  margin: auto 15px auto 0;
  width: 70px;
  height: 50px;
}

.inspect-ammo-type-label {
  margin: auto 0;
  text-transform: uppercase;

  font-size: 20px;
  height: 20px;
  line-height: 20px;
}

.inspect-energy-type-container {
  border: 1px solid yellow;
}

.inspect-energy-type-icon {
  display: block;
  height: 20px;
  width: 20px;
  margin: auto 0 auto 0;
  padding: 0px;
  /* border: 1px solid magenta; */
}

.inspect-energy-capacity {
  font-weight: bold;
  font-size: 35px;
  height: 35px;
  line-height: 35px;

  margin: auto 10px auto 2px;
  /* border: 1px solid magenta; */
}

.inspect-energy-capacity-label {

  font-size: 20px;
  height: 20px;
  line-height: 20px;

  text-transform: uppercase;
  margin: auto 0;
}

.inspect-power-display-container {
  height: fit-content;
  /* border: 1px solid lightgreen; */
  display: flex;
}

.inspect-power-section-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px;
  /* border-bottom: 2px solid darkgray; */
}

.inspect-power-display {
  border-right: 2px solid darkgray;
  margin: 0 15px 0 0;
  padding: 0 15px 0 0;
  font-size: 60px;
  line-height: 50px;
  height: 50px;
  display: block;
}

.perk-display-container {
  flex-direction: column;
  display: flex;
  /* padding: 10px 0; */
  /* gap: 10px; */
  border-top: 2px solid darkgray;
}

.inspect-perk-container {
  /* border: 1px solid magenta; */
  width: 100%;
  display: flex;
  padding: 5px 10px;
  gap: 5px;
}

.inspect-perk-container.intrinsic {
  /* border: 1px solid magenta; */
  width: 100%;
  display: flex;
  background-color: rgba(100, 100, 100, 0.4);
}

.inspect-perk-container.enhanced {
  border-top: 2px solid rgba(214, 180, 30, 0.4);
  background: linear-gradient(to bottom, rgba(187, 158, 31, 0.25), transparent 50%);
}

.inspect-perk-name {
  display: inline-block;
  margin: auto 0 auto 5px;
  /* border: 1px solid red; */
}

.inspect-perk-icon {
  display: inline-block;
  height: 30px;
  width: 30px;
}

.inspect-perk-icon.inactive {
  /* filter: brightness(40%); */
  opacity: 0.3;
}

.stat-display-container {
  flex-direction: column;
  display: flex;
  gap: 0px;
  border-top: 2px solid darkgray;
  padding: 10px;
}

.stat-container {
  display: flex;
  gap: 10px;
  height: 25px;
  /* border: 1px solid magenta; */
}

.stat-title {
  margin: auto 0;
  width: 40%;
  text-align: right;
  color: darkgray;
}

.stat-bar-container {
  margin: auto 0;
  flex: 1;
  background: rgba(255, 255, 255, 0.15);
  height: 15px;
  display: flex;
  position: relative;
}

.stat-bar {
  background: white;
  height: 100%;
  max-width: 100%;
}

.stat-value {
  font-weight: bold;
  margin: auto 0;
  width: 10%;
}

.full-page-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  padding: 0;
  display: flex;
}

.page-container {
  padding: 30px 10px 10px 10px;
  max-width: var(--main-content-max-width);
  margin: 0 auto;
}

.profile-selector-container {
  min-width: 500px;
  min-height: 300px;
  backdrop-filter: blur(var(--blur-px));
  margin: auto;
}

.profile-selector-title {
  margin: 0;
  background-color: rgba(70, 70, 70, 0.6);
  border-top: 3px solid lightgrey;
  padding: 20px;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
}

.profile-selector-content {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3);
}

.profile-selector-controls {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  display: flex;
  gap: 10px;
}

.profile-selector-controls :first-child {
  margin-left: auto;
}

.profile-card {
  cursor: pointer;
  border: 2px solid lightgrey;
  background-color: rgb(65, 65, 65);
  padding: 5px;
}

.profile-card.selected {
  outline: 2px solid lightgrey;
  outline-offset: 2px;
}

.profile-title {
  margin: 0;
}

.weapon-type-selector-container {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.weapon-type-row-container {
  /* border: 1px solid magenta; */
  /* padding: 10px; */
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(var(--weapon-type-icon-width), 1fr));
  gap: 10px;
}



.weapon-type-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: calc(100% - 10px);
  max-height: 50%;
  margin: auto 0;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  /*rotate(-30deg);*/
}

.loadouts-page-container {
  max-width: var(--main-content-max-width);
  margin: 0 auto;
  padding: 30px 10px 10px 10px;
}

.loadout-selector-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.loadout-container {
  /* border: 2px solid darkgray; */
  /* padding: 20px; */
  backdrop-filter: blur(var(--blur-px));
}

.loadout-header {
  background-color: rgba(63, 63, 63, 0.6);
  padding: 1em;
  /* border-top: 5px solid lightgrey; */
}

.loadout-title {
  /* font-size: 1.5rem; */
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
}

.loadout-subtitle {
  font-size: 0.75rem;
  margin: 0;
  font-weight: normal;
  color: lightgrey;
}

.loadout-details-container {
  display: flex;
  background-color: black;
  padding: 0.5em;
  /* --subclass-size: 70px; */
}

.loadout-equipment-container {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 20px;
  padding: 20px;
  /* border: 1px solid red; */
  /* --item-size: 70px; */
  /* font-size: 0.7rem; */
}

.loadout-items-container {
  flex: 1;
  /* border: 1px solid magenta; */
  display: grid;
  flex-direction: column;
  /* flex-flow: wrap; */
  grid-template-columns: repeat(auto-fill, 100px);
  gap: 10px;
  margin: auto 0;
  /* transform: scale(0.5) */
}

.loadout-controls-container {
  display: flex;
  padding: 10px;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}

.loadout-controls-container> :first-child {
  /* border: 1px solid red; */
  margin-left: auto;
}

.item-wrapper.subclass img.item-icon {
  transform: rotate(-45deg) scale(1.414);
}

@keyframes equip-prompt {
  0% {
    outline-offset: 15px;
    outline-color: rgba(255, 255, 255, 0.0);
  }

  75% {
    outline-offset: 2px;
    outline-color: rgba(255, 255, 255, 1);
  }

  100% {
    outline-offset: 2px;
    outline-color: rgba(255, 255, 255, 0);
  }
}

.equipped {
  height: fit-content;
}

.equipped.prompt {
  /* padding: 10px; */
  outline-width: 3px;
  outline-style: solid;
  outline-offset: 4px;
  animation: equip-prompt ease-out 1s infinite;
}

@keyframes item-wrapper-after-transfer {
  0% {
    /* transform: rotate(0deg); */
    height: 0%;
    bottom: 0;
  }

  50% {
    height: 100%;
  }

  100% {
    /* transform: rotate(360deg); */
    height: 0%;
    top: 0;
  }
}

@keyframes item-wrapper-transfer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    /* display: none; */
  }

  100% {
    opacity: 0;
    /* display: none; */
  }

}

.item-wrapper {
  /* --rect-height: 50%; */
  --anim-time: 0.5s;
  width: var(--item-size);
  height: var(--item-size);
  position: relative;
}

.item-wrapper.transfer>.item {
  animation: item-wrapper-transfer var(--anim-time) steps(1, end);
}

.item-wrapper.transfer::after {
  visibility: visible;
  animation: item-wrapper-after-transfer var(--anim-time) linear;
  z-index: 50;
  content: "";
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  height: var(--rect-height);
}

.item-wrapper.subclass {
  /* border: 1px solid magenta; */
  width: var(--subclass-size);
  height: var(--subclass-size);
}

.item-wrapper>.item {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

@keyframes subclass-hover {
  from {}

  to {}
}

.item-wrapper.subclass>.item {
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70.72%;
  height: 70.72%;
}

.inventory>.item-wrapper.subclass {
  width: var(--subclass-size-small);
  height: var(--subclass-size-small);
}

.inventory.subclass {
  grid-template-columns: repeat(auto-fill, var(--subclass-size-small));
  gap: var(--item-grid-gap);
}



/* .static-controls-container>* {
  border: 2px solid magenta;
} */

.character-overlay-icon-container {
  position: relative;
}

.character-overlay-icon {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 10%;
  grid-area: icon;
  /* top: 10px; */
  /* left: 20px; */
}

.character-selector-container {
  display: flex;
  gap: 15px;
  /* border: 1px solid red; */
  /* padding: 10px 0; */
  height: 50px;
  margin: auto 0;
  grid-area: char;
}

.character-container {
  user-select: none;
  position: relative;
  height: 50px;
  width: 50px;
  /* width: fit-content; */
  border: 2px solid lightgrey;
  border-radius: 50%;
  cursor: pointer;
  margin: auto 0;
  overflow: hidden;
  filter: brightness(70%);
  padding: 8px;
}

.menu-tabs-container {
  user-select: none;
  /* border: 2px solid magenta; */
  display: flex;
  gap: 10px;
  font-size: 1.5em;
  /* overflow-x: hidden; */
  /* overflow-x: hidden; */
  /* overflow-y: visible; */
  grid-area: tabs;
}

@media (max-width: 1150px) {

  .nav-bar {
    /* border: 5px solid red; */
    padding: 0 10px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 50px 1fr;
    grid-template-areas:
      "icon char"
      "tabs tabs";
  }

  .menu-tabs-container {
    font-size: 1rem;
  }

  .refresh-button {
    height: 100%;
  }

}

@media (max-width: 500px) {

  :root {
    --item-size: 60px;
    --subclass-size: 108px;
    --subclass-size-small: 60px;
  }

  .item {
    font-size: 0.6em;
    border-width: 1px;
  }

  .item.masterwork {
    border-width: 2px;
  }

  .loadout-selector-container-2 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

}

@media (max-width: 620px) {

  :root {
    --weapon-type-icon-width: 40px;
    --weapon-type-icon-height: 40px;
  }

  .menu-tabs-container {
    font-size: 0.8rem;
  }

}

.hotkey {
  border: 1px solid white;
  background-color: #222;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
  border-radius: 0.25em;
  width: fit-content;
  height: fit-content;
  padding: 0 0.25em;
  opacity: 0.75;
  font-size: 0.7em;
}

.menu-tabs-container>* {
  margin: auto 0;
}

.menu-tab {
  color: white;
  opacity: 0.8;
  text-shadow: 1px 1px black;
  cursor: pointer;
  height: 100%;
  text-transform: uppercase;
  /* font-weight: 500; */
  /* border: 1px solid red; */
  position: relative;
  padding: 10px 10px;
  /* height: 40px; */
  transition: opacity 0.3s linear;
  display: flex;
  z-index: 5;
}

.menu-tab>* {
  margin: auto 0;
  /* border: 1px solid red; */
}

.menu-tab>img {
  height: 1.5em;
  /* border: 1px solid red; */
  filter: drop-shadow(1px 1px 0px black);
}

.menu-tab:hover, .menu-tab.selected {
  opacity: 1;
}

.menu-tab::after {
  content: '';
  /* border: 1px solid red; */
  transform: translateX(-50%);
  background-color: lightgray;
  height: 6px;
  width: 0%;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transition: all 0.25s ease-out;
  /* transition-delay: 0.2s; */
  opacity: 0;
  /* z-index: 1000; */
}

.menu-tab.selected::after {
  width: 100%;
  opacity: 1;
  /* z-index: 1000; */
}

.settings-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  /* background-color: black; */
  /* padding: 50px; */
}

.tab-container {
  /* overflow-x: none; */
  max-width: var(--main-content-max-width);
  margin: 0 auto;
  padding: 50px 10px 500px 10px;
  /* z-index: 3; */
}

.tab-controls-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(var(--blur-px));
}

.tab-controls-content-container {
  top: 0;
  position: sticky;
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
}



.perk-cols-container {
  border-top: 2px solid darkgray;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.perk-col {
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.perk {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 4px;
  /* background-color: rgb(77, 141, 214); */
  border: 1px solid rgb(83, 83, 83);
  border-radius: 50%;
  /* border: 1px solid lightgreen; */
  position: relative;
}

.perk-divider {
  width: 100%;
  height: 1px;
  background-color: rgb(83, 83, 83);
}

.perk-col-divider {
  width: 1px;
  /* height: 100%; */
  background-color: rgb(83, 83, 83);
}

.perk.legacy {
  border-style: dashed;
  background-image: linear-gradient(to top, transparent, rgb(117, 63, 0) 80%);
}

.perk.rolled {
  border: 1px solid lightgrey;
  opacity: 1;
  background-color: hsl(204, 50%, 20%)
}

.perk.selected {
  opacity: 1;
  border-color: white;
  background-color: hsl(204, 50%, 55%);
}

.perk.enhanced {
  /* border-width: 2px; */
  background-image: linear-gradient(to top, rgba(255, 195, 31, 0.6), transparent 70%);
}

.perk.enhanced.selected {
  border-color: rgba(255, 232, 26, 0.89);
}


.perk-icon {
  width: 100%;
  opacity: 0.6;
}

.perk.rolled .perk-icon,
.perk.selected .perk-icon {
  opacity: 01;
}

/* .perk::after {
  
} */

.perk:hover::after {
  pointer-events: none;
  z-index: 1;
  display: block;
  visibility: hidden;
  content: attr(data-name);
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  border: 1px solid lightgrey;
  width: fit-content;
  min-width: 150px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: visible;
}

.mouse-element {
  text-align: center;
  width: 230px;
  pointer-events: none;
  user-select: none;
  position: fixed;
  z-index: 1000;
  overflow: hidden;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(var(--blur-px));
  /* border: 2px solid lightgrey; */
  font-size: 1.5em;
  padding: 10px 10px 20px 10px;
  transform: translate(-50%, calc(-70px - 100%));
}

.mouse-element::after {
  content: "";
  position: absolute;
  background-color: rgb(139, 139, 139);
  width: 100%;
  height: 10px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  z-index: -1;
}

.progress-bar {
  position: absolute;
  background-color: rgb(84, 196, 84);
  width: 0;
  height: 10px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

/* GridPager anims ---------------------------------------------------------------------------*/
@keyframes page-in-left {
  0% {
    transform: translate(-15px, 0);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes page-out-left {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(-15px, 0);
    opacity: 0;
  }
}

@keyframes page-in-right {
  0% {
    transform: translate(15px, 0);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes page-out-right {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(15px, 0);
    opacity: 0;
  }
}

/* Pager anims ---------------------------------------------------------------------------*/
@keyframes pager-page-in-left {
  0% {
    transform: translate(-300px, 0);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes pager-page-out-left {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(-300px, 0);
    opacity: 0;
  }
}

@keyframes pager-page-in-right {
  0% {
    transform: translate(300px, 0);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes pager-page-out-right {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: translate(300px, 0);
    opacity: 0;
  }
}

.gridpager-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  /* border: 2px solid red; */
}

.pager-content-container {
  animation-duration: 0.25s;
  /* border: 1px solid orange; */
}

.pager-page-container {
  position: relative;
  height: 600px;
  /* border: 4px solid magenta; */
}

.pager-grid-container {
  animation-duration: 0.125s;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--item-size));
  grid-template-rows: repeat(auto-fit, var(--item-size));
  gap: var(--item-grid-gap);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pager-test-item {
  /* border: 1px solid magenta; */
  width: 100px;
  height: 100px;
  user-select: none;
  text-align: center;
}

.pager-details-container {
  padding: 3px 0;
  border-bottom: 3px solid darkgray;
  margin-bottom: 10px;
  display: flex;
}

.pager-details-container>div {
  color: darkgray;
}

.pager-page-display {
  display: block;
  font-size: 1.5em;
  /* margin-left: auto; */
}

.pager-button-container {
  display: flex;
  gap: 10px;
  /* border: 1px solid red; */
}

/* .pager-button-container:first-child {
  margin-left: auto;
  border: 1px solid magenta;
} */

.pager-button {
  user-select: none;
  display: block;
  width: 70px;
  height: 70px;
}

.pager-button:disabled {
  opacity: 0.25;
}

/* .pager-button:disabled .pager-button-icon {
  filter: brightness(50%);
} */

.pager-button-icon {
  width: 100%;
  height: 100%;
}

.pager-button-icon.left {
  transform: rotate(180deg);
}


.login-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  padding: 0;
  display: flex;
}

.login-content {
  margin: auto auto;
  display: flex;
  flex-direction: column;
}

.login-title {
  /* border: 1px solid magenta; */
  font-size: 3em;
  margin: 0;
  padding: 0 50px;
  margin-bottom: 50px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: center;
}

.login-button {
  padding: 20px 0;
  font-size: 2em;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.loadout-equip-container {
  /* border: 1px solid magenta; */
  /* padding: 20px; */
  margin-bottom: 25px;
  backdrop-filter: blur(var(--blur-px));
}

.loadout-equip-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.loadout-equip-header {
  background-color: rgba(44, 88, 139, 0.7);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-top: 5px solid lightgrey;
}

.loadout-equip-title {
  letter-spacing: 0.2em;
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
}

.loadout-equip-subtitle {
  margin: 0;
  font-weight: normal;
  color: lightgrey;
}

.loadout-equip-progress-bar-container {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 30px;
}

.loadout-equip-progress-bar {
  background-color: white;
  width: 0%;
  height: 100%;
  transition: width 0.25s ease-in-out;
}

.loadout-equip-mods-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 60px);
}

.loadout-equip-mod {
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  border: 3px solid lightgrey;
}

.loadout-equip-mod:hover::after {
  pointer-events: none;
  content: attr(data-name);
  display: block;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 10px;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 10;
}

.loadout-equip-mod-icon {
  width: 100%;
  height: 100%;
}

.donate-button {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.25em;
  background-color: #17bee5;
  padding: 5px 50px;
  border-radius: 50px;
  margin: 0 auto;
}

.feedback-button {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.25em;
  background-color: #3bb95b;
  padding: 5px 50px;
  border-radius: 50px;
  margin: 0 auto;
}

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.settings-section-title {
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 1.5em;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: lightgrey;
  border-bottom: 1px solid lightgrey;
}

.settings-button {
  font-size: 1.25em;
  width: 100%;
  padding: 10px;
  text-align: right;
}

.setting-container {
  user-select: none;
  display: flex;
  font-size: 1.25em;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
}

.setting-container.primary {
  background-color: rgba(60, 111, 158, 0.4);
}

.setting-container.primary::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  background-color: lightgrey;
}

.setting-container>* {
  padding: 10px 20px;
}


.setting-label {
  flex: 1;
}

.setting-option {
  border: 1px solid lightgrey;
  min-width: 300px;
  text-align: center;
  cursor: pointer;
  background-color: var(--button-background-color);
}



.loadout-2 {
  border: 1px solid magenta;
  /* height: 500px; */
}

.crafting-progress-container {
  z-index: 0;
  /* border: 1px solid red; */
  padding: 0.2em;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
}

.crafting-progress-details {
  position: relative;
  font-size: 1.1em;
  z-index: 1;
  text-shadow: 1px 1px 1px black;

}

.crafting-percent {
  float: right;
}

.crafting-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: orangered;
  /* height: 100%; */
  /* border: 1px solid orange; */
}

.armory-filter-controls-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.armory-filter-static-controls-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  /* padding: 0 10px; */
  /* border: 1px solid red; */
  height: 3em;
}

.armory-filter-button {
  /* backdrop-filter: blur(100px); */
  border: 1px solid white;
  opacity: 0.6;
}

.armory-filter-button.selected {
  background-color: rgba(128, 128, 128, 0.5);
  opacity: 1;
}

.armory-filter-button.item-type {
  letter-spacing: 0.2em;
}

.armory-filter-button.weapon-type {
  user-select: none;
  cursor: pointer;
  height: var(--weapon-type-icon-height);
  /* width: var(--weapon-type-icon-width); */
  position: relative;
  overflow: hidden;
  display: flex;
}



.armory-filter-dynamic-container {
  grid-area: dynamic;
  border: 1px solid greenyellow;
}

/* .armory-page-container {
  height: var(--full-page-height);
  padding: 30px 10px 10px 10px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 900px;
  max-width: 1500px;
  margin: 0 auto;
  gap: 10px;
} */

.armory-page-container {
  height: var(--full-page-height);
  padding: 30px 10px 10px 10px;
  /* border: 2px solid greenyellow; */
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 300px 1fr; */
  max-width: var(--main-content-max-width);
  margin: 0 auto;
  gap: 10px;
}

.unique-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 75px);
  gap: 5px;
}

.unique-item-container {
  width: 75px;
  height: 75px;
  border: 2px solid lightgrey;
}

.unique-item-container:hover::after {
  pointer-events: none;
  white-space: pre-line;
  position: absolute;
  z-index: 10;
  content: attr(data-desc);
  padding: 5px;
  background-color: black;
}

.unique-item-container>img {
  width: 100%;
  height: 100%;
}

.vault-cleaner-item-container {
  display: flex;
  gap: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;
  backdrop-filter: blur(var(--blur-px));
  background: rgba(0, 0, 0, 0.25);
}

.loadout-item {
  width: 60px;
  height: 60px;
  border: 1px solid lightgray;
}

.loadout-item.masterwork {
  border: 2px solid rgb(248, 223, 0);
}

.loadout-2-container {
  /* border: 2px solid lightgray; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(var(--blur-px));
  user-select: none;
  transition-duration: 0.25;
  transition-timing-function: ease-out;
  transition-property: outline-color, outline-offset, border-image, border-color;
  transition-timing-function: linear;
  transition-duration: var(--item-hover-transition-time);
  outline: 3px solid transparent;
  outline-offset: 7px;
}

.loadout-container-2-row {
  gap: 5px;
  /* border: 1px solid lightgreen; */
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.loadout-selector-container-2 {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(400px, 1fr)); */
}

@media (max-width: 500px) {
  .loadout-item {
    width: 50px;
    height: 50px;
  }

  .loadout-selector-container-2 {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.loadout-2-header {
  z-index: 1;
  /* border: 1px solid magenta; */
  display: flex;
  flex-direction: column;
  background-color: rgba(56, 56, 56, 0.7);
  padding: 15px 10px;
}

.loadout-2-container:hover {
  outline-color: lightgray;
  outline-offset: 2px;
}

.loadout-2-header.current {
  background-color: rgba(44, 88, 139, 0.7);
}

.loadout-2-title {
  text-transform: uppercase;
  font-size: 1.5em;
  /* border: 1px solid red; */
  margin: auto 0;
}

.loadout-2-subtitle {
  font-size: 1em;
  /* border: 1px solid red; */
  color: darkgrey;
  margin: auto 0;
}

.loadout-2-content {
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
}

.loadout-2-footer {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
}

.loadout-2-footer>:first-child {
  margin-left: auto;
}

.loadout-2-subclass {
  /* border: 1px solid magenta; */
}

.loadout-2-subclass-img-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid lightgray;
  transform: scale(calc(1/1.415)) rotate(-45deg);
}

.loadout-2-subclass-img-wrapper>img {
  width: 100%;
  height: 100%;
  transform: scale(1.415) rotate(45deg);
}

.loadout-details-item-img {
  display: inline;
  width: 70px;
  height: 70px;
}

.loadout-details-plug-img {
  display: inline;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: relative;
}

.loadout-details-plug-img-container {
  display: inline;
}

.loadout-details-plug-img-container:hover::after {
  pointer-events: none;
  content: attr(data-name);
  white-space: pre-line;
  position: absolute;
  z-index: 10;
  padding: 5px;
  background-color: black;
}

.loadout-details-component-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.main-bottom-controls-container {
  z-index: 10;
  position: absolute;
  bottom: 0;
  /* top: 100%; */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.tools-selector-container {
  /* border: 2px solid magenta; */
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  flex-direction: column;
}

.tool-button {
  padding: 1em;
  font-size: 1.25em;
  letter-spacing: 0.2em;
  /* border: 2px solid red; */
}